/*eslint-disable*/
import {
  marketPlace,
  insurance,
  website,
  transportation,
  singleSignOn,
  veterinary,
} from "@/axios";

export default {
  getAllNotifications() {
    return website().get("/user/notifications");
  },
  getInsuranceCount () {
    return insurance().get('insurances/user/count')
  },
  getTransportationCount () {
    return transportation().get('trips/user/count')
  },
  getReservationsCount () {
    return veterinary().get('customer/reservations/total')
  },
  getOrdersCount () {
    return marketPlace().get('customers/orders/count')
  },
  getFavouritesCount () {
    return marketPlace().get('customer/wish-lists/count')
  },
  getWalletBalance () {
    return website().get('user/wallets/balance')
  },
  getAllOrders (page) {
    return marketPlace().get(`customer/orders?page=${page}`)
  },
  getOrderInvoice(orderId) {
    return marketPlace().get(`customer/order/invoice2/${orderId}`);
  },
  getUserOrder(id, company, status, page) {
    return insurance().get(
      `insurances/user?id=${id}&company=${company}&status=${status}&page=${page}`
    );
  },
  getInsuranceCompany() {
    return insurance().get("insuranceServiceProvider/available");
  },
  getInsuranceInvoice(id) {
    return insurance().get(`insurances/invoice2/${id}`);
  },
  getUserTripsOrder(status, page = 1) {
    return transportation().get(
      `trips/user?filters[status]=${status}&page=${page}`
    );
  },
  getTripsStatus() {
    return transportation().get("trips/status");
  },
  getTripsCount() {
    return transportation().get("trips/user/count");
  },
  addComment(payload) {
    return transportation().post("v1/user/reviews", payload);
  },
  // wallet
  getWallaetDetails() {
    return website().get("user/wallets");
  },
  //masterCardTransaction
  getMasterTransaction(page) {
    return website().get(
      `user/wallets/credit-transactions?page=${page}&status=completed`
    );
  },
  //////
  editWalletInfo(payload) {
    return website().patch("user/wallets", payload);
  },
  editPinCode(payload) {
    return website().patch("user/wallets/change-pin", payload);
  },
  forgetPinCode(payload) {
    return website().post("user/wallets/forget-pin", payload);
  },
  createPinCode(payload) {
    return website().post("user/wallets/reset-pin", payload);
  },
  requestWithdraw(payload) {
    return website().post("user/wallets/withdrawal", payload);
  },
  deposit(payload) {
    return website().post("user/wallets/deposit", payload);
  },
  initiate(payload) {
    return website().post("payment/initiate", payload);
  },
  reservationStatus(payload) {
    return veterinary().post("customer/reservations/payment-res", payload);
  },
  checkPaymentStatus(payload) {
    return website().post("payment/check-status", payload);
  },
  getAllTransactions(page) {
    return website().get(
      `user/wallets/transactions?page=${page}&status=completed`
    );
  },
  successDeposit(data) {
    return website().post("user/wallets/success-deposit", data);
  },
  // getUserInfo () {
  //   return singleSignOn().get('v1/profile/users')
  // },
  getUserData() {
    return singleSignOn().get("v1/profile/users");
  },
  editUserProfile(payload) {
    return singleSignOn().put("v1/profile/users", payload);
  },
  uploadProfile(payload) {
    return website().post("upload", payload);
  },
  changePassword(payload) {
    return singleSignOn().patch("v1/profile/users/change-password", payload);
  },
  createPassword(payload) {
    return singleSignOn().post("sso-reset-password", payload);
  },
  resetPassword (payload) {
    return singleSignOn().post('sso-reset-password', payload)
  },
  verifyResetPassword (payload) {
    return singleSignOn().post('sso-verify-reset-password', payload)
  }
}
